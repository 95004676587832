<template>
    <div class="alert--global" v-show="show" ref="alert">
        <Message :message="message" :class="'message--' + level"></Message>
        <FormButton
            class="l-button--icon-only l-button--secondary l-button--size-30 message-button"
            @click="resetMessage"
        >
            <IconsClose />
        </FormButton>
    </div>
</template>

<script setup>
import { watch } from 'vue';
import { onMounted } from 'vue';

const message = ref('');
const level = ref('');
const show = ref(false);
const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const alert = ref(null);

function resetMessage() {
    show.value = false;
    message.value = '';
    level.value = '';
    router.replace({ query: null });
}

const alertMessages = computed(() => {
    return {
        passwordChangedSuccess: {
            text: t('alert.passwordChanged'),
            level: 'success',
        },
        loggedIn: {
            text: t('alert.loggedIn'),
            level: 'dark-bg',
        },
        linkCopied: {
            text: t('alert.linkCopied'),
            level: 'dark-bg',
        },
        emailCopied: {
            text: t('alert.emailCopied'),
            level: 'dark-bg',
        },
        phoneCopied: {
            text: t('alert.phoneCopied'),
            level: 'dark-bg',
        },
        basketEmpty: {
            text: t('alert.basketEmpty'),
            level: 'dark-bg',
        },
    };
});

function checkForDisplay() {
    let code = route.query.alert;
    if (localStorage) {
        if (
            localStorage.getItem('passwordResetInit') === '1' &&
            route.hash.includes('kc_action_status=success')
        ) {
            code = 'passwordChangedSuccess';
        }
        localStorage.setItem('passwordResetInit', '0');
    }
    if (!code || !Object.keys(alertMessages.value).includes(code)) return;
    message.value = alertMessages.value[code].text;
    level.value = alertMessages.value[code].level;
    show.value = true;
    setTimeout(resetMessage, 6000);
}

const query = computed(() => {
    return route.query;
});

onMounted(() => {
    window.addEventListener('click', (e) => {
        if (show.value) {
            resetMessage();
        }
    });
});

watch(query, () => {
    checkForDisplay();
});

onMounted(() => {
    checkForDisplay();
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.alert--global {
    position: fixed;
    top: 15px;
    display: flex;
    width: 100%;
    max-width: 1060px;
    left: 50%;
    height: 60px;
    border: 1px solid $color-dark-disabled;
    border-radius: 12px;
    background-color: $color-dark-02;
    transform: translateX(-50%);
    z-index: 100000;
    :deep(.message) {
        text-align: left;
        color: $color-light;
        height: 100%;
        flex-grow: 1;
        background-color: $color-dark-02;
        line-height: 38px;
    }
}
@include media-breakpoint-down(1200) {
    .alert--global {
        max-width: calc(100% - 40px);
    }
}
.message-button {
    margin-top: 14px;
    margin-right: 16px;
}
</style>
